export const TOKEN_QUERY_PARAM_NAME = "sso_token";
export const GUEST_TOKEN_QUERY_PARAM_NAME = "token";

export const getTokenFromURL = (url: string, type: "sso" | "guest" = "sso") => {
  const urlParams = new URLSearchParams(url);

  return urlParams.get(
    type === "sso" ? TOKEN_QUERY_PARAM_NAME : GUEST_TOKEN_QUERY_PARAM_NAME,
  );
};
