import { createBrowserRouter } from "react-router-dom";
import { OverviewPage } from "./features/cases/pages/OverviewPage";
import { CasesPage } from "./features/cases/pages/CasesPage";
import { MovementsPage } from "./features/movements/pages/MovementsPage";
import { CaseDetailPage } from "./features/cases/pages/CaseDetailPage";
import { UsersPage } from "./features/users/pages/UsersPage";
import { ProfilePage } from "./features/session/pages/ProfilePage";
import { ChangePasswordPage } from "./features/session/pages/ChangePasswordPage";
import { EventsPage } from "./features/events/pages/EventsPage";
import { CaseWithConnectionPage } from "./features/cases/pages/CaseWithConnectionPage";
import { CaseWithoutConnectionPage } from "./features/cases/pages/CaseWithoutConnectionPage";
import { CredentialsPage } from "./features/credentials/pages/CredentialsPage";
import { CasesDataPage } from "./features/casesData/pages/CasesDataPage";
import { ClientsPage } from "./features/clients/pages/ClientsPage";
import { TasksPage } from "./features/tasks/pages/TasksPage";
import { NotificationsPage } from "./features/session/pages/NotificationsPage";
import { MainContainer } from "./ui/containers/MainContainer";

export const routes = {
  home: "/",
  cases: "/cases",
  caseWithConnection: "/cases/case-with-connection",
  caseWithoutConnection: "/cases/case-without-connection",
  movements: "/movements",
  clients: "/clients",
  connections: "/connections",
  credentials: "/credentials",
  users: "/users",
  profile: "/profile",
  changePassword: "/change-password",
  notifications: "/notifications",
  events: "/events",
  casesData: "/case-data",
  tasks: "/tasks",
};

export const router = createBrowserRouter([
  {
    path: routes.home,
    element: <MainContainer />,
    handle: {
      crumb: () => ({ link: `${routes.home}`, name: "breadcrumbs.home" }),
    },
    children: [
      {
        index: true,
        element: <OverviewPage />,
      },
      {
        path: routes.clients,
        element: <ClientsPage />,
        handle: {
          crumb: () => ({
            link: routes.clients,
            name: "breadcrumbs.clients",
          }),
        },
      },
      {
        path: routes.cases,
        handle: {
          crumb: () => ({
            link: `${routes.cases}`,
            name: "breadcrumbs.cases",
          }),
        },
        children: [
          {
            index: true,
            element: <CasesPage />,
          },
          {
            path: `${routes.cases}/:caseId`,
            element: <CaseDetailPage />,
            handle: {
              crumb: (customName: string) => ({
                link: `${routes.cases}`,
                name: customName,
                hasCustomName: Boolean(customName),
              }),
            },
          },
          {
            path: `${routes.caseWithConnection}`,
            element: <CaseWithConnectionPage />,
            handle: {
              crumb: () => ({
                link: `${routes.caseWithConnection}`,
                name: "breadcrumbs.case_with_connection",
              }),
            },
          },
          {
            path: `${routes.caseWithoutConnection}`,
            element: <CaseWithoutConnectionPage />,
            handle: {
              crumb: () => ({
                link: `${routes.caseWithoutConnection}`,
                name: "breadcrumbs.case_without_connection",
              }),
            },
          },
        ],
      },
      {
        path: routes.movements,
        element: <MovementsPage />,
        handle: {
          crumb: () => ({
            link: `${routes.movements}`,
            name: "breadcrumbs.movements",
          }),
        },
      },
      {
        path: routes.events,
        element: <EventsPage />,
        handle: {
          crumb: () => ({
            link: `${routes.events}`,
            name: "breadcrumbs.events",
          }),
        },
      },
      {
        path: routes.users,
        element: <UsersPage />,
        handle: {
          crumb: () => ({
            link: `${routes.users}`,
            name: "breadcrumbs.users_and_permissions",
          }),
        },
      },
      {
        path: routes.credentials,
        element: <CredentialsPage />,
        handle: {
          crumb: () => ({
            link: `${routes.credentials}`,
            name: "breadcrumbs.credentials",
          }),
        },
      },
      {
        path: routes.casesData,
        element: <CasesDataPage />,
        handle: {
          crumb: () => ({
            link: `${routes.casesData}`,
            name: "breadcrumbs.settings",
          }),
        },
      },
      {
        path: routes.profile,
        element: <ProfilePage />,
        handle: {
          crumb: () => ({
            link: `${routes.users}`,
            name: "breadcrumbs.profile",
          }),
        },
      },
      {
        path: routes.changePassword,
        element: <ChangePasswordPage />,
        handle: {
          crumb: () => ({
            link: `${routes.users}`,
            name: "breadcrumbs.change_password",
          }),
        },
      },
      {
        path: routes.notifications,
        element: <NotificationsPage />,
        handle: {
          crumb: () => ({
            link: `${routes.users}`,
            name: "breadcrumbs.change_password",
          }),
        },
      },
      {
        path: routes.tasks,
        element: <TasksPage />,
        handle: {
          crumb: () => ({
            link: `${routes.tasks}`,
            name: "breadcrumbs.tasks",
          }),
        },
      },
    ],
  },
]);
