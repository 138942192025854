import { SSO_URL } from "../../../configs";

export const navigateToSSO = (queryParams: {
  tenant: string;
  isLogout?: boolean;
}) => {
  const url_with_tenant = SSO_URL.replace("{tenant}", queryParams.tenant);
  const url = new URL(
    queryParams?.isLogout ? `${url_with_tenant}/logout` : url_with_tenant,
  );

  url.searchParams.append("app", "ct-smb");
  window.location.href = url.toString();
};
