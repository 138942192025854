import { STORAGE_SESSION_NAME } from "../conts";
import { navigateToSSO } from "./navigateToSSO";

export const signOut = () => {
  localStorage.removeItem(STORAGE_SESSION_NAME);

  const tenant = window.location.hostname.split(".")[0];
  navigateToSSO({
    tenant,
    isLogout: true,
  });
};
