import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Providers } from "./Providers.tsx";
import { router } from "./router.tsx";
import "./utils/analytics/initAnalytics.ts";
import "./utils/i18n/i18nInit";
import "./global.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Providers>
    <RouterProvider router={router} />
  </Providers>,
);
