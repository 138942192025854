import { IS_DEV, SPLIT_API_KEY } from "@/configs";
import localTreatments from "./local_feature_flags.json";

export const splitSetup = (tenantName: string) => ({
  core: {
    authorizationKey: SPLIT_API_KEY,
    key: tenantName,
  },
  features: IS_DEV ? localTreatments : undefined,
  scheduler: {
    offlineRefreshRate: 15, // 15 sec
  },
});
