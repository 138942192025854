import {
  Box,
  Checkbox,
  DatePicker,
  Dropdown,
  FilterButton,
  Icon,
  IconButton,
  Input,
  SelectMultiCheckbox,
  Table,
  Tag,
  Text,
  Tooltip,
} from "@suit-ui/react";

import {
  MdMoreVert,
  MdOutlinePublic,
  MdSearch,
  MdOutlineArrowForward,
} from "react-icons/md";
import { PageContainer } from "../../../ui/containers/PageContainer";
import { useNavigate } from "react-router-dom";
import { CaseFilter, useGetCases } from "../useCases/useGetCases";
import { PageTitle } from "@/ui/texts/PageTitle";
import { useGetCourts } from "../useCases/useGetCourts";
import { BreadcrumbNav } from "@/ui/navbars/BreadcrumbNav";
import { useTranslation } from "react-i18next";
import { NewCaseModal } from "../components/NewCaseModal";
import { useUserAccess } from "@/features/session/useCases/useUserAccess";
import { UserAccess } from "@/features/session/components/UserAccess";
import { useGetCustomFields } from "@/features/customFields/useCases/useGetCustomFields";
import { useMemo, useState } from "react";
import { useGetUsers } from "@/features/users/useCases/useGetUsers";
import { useCurrentUser } from "@/features/session/useCases/useCurrentUser";
import { routes } from "@/router";
import { getCustomFieldValueFormatted } from "@/features/customFields/useCases/getCustomFieldValueFormatted";
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { useDebounce } from "@/utils/debounce/useDebounce";
import { TableFooter } from "@/ui/TableFooter";
import { TableBody } from "@/ui/TableBody";
import { CustomFieldFilter } from "@/features/customFields/components/CustomFieldFilter";
import { useFilters } from "@/utils/filter/useFilters";
import { useDateLocale } from "@/utils/i18n/useDateLocale";
import { useGetClients } from "@/features/clients/useCases/useGetClients";
import ConfirmCheck from "../components/ConfirmCheck";
import { useActivateCases } from "../useCases/useActivateCases";
import { useDeactivateCases } from "../useCases/useDeactivateCases";
import Confirm from "@/ui/Confirm";
import { trackEvent } from "@/utils/analytics/trackEvent";
import { useAssociationUsers } from "../useCases/useAssociationUsers";
import { useRemoveAssociationUsers } from "../useCases/useRemoveAssociationUsers";
import { CaseUserAssignModal } from "../components/CaseUserAssignModal";
import { DownloadCases } from "../components/DownloadCases";
import { DeleteCaseDropdownItem } from "../components/DeleteCaseDropdownItem";

export function CasesPage() {
  const locale = useDateLocale();
  const [filters, setFilters] = useFilters<CaseFilter>({
    active: {
      eq: true,
    },
    custom_data: {},
  });
  const { t } = useTranslation();
  const courts = useGetCourts();
  const clients = useGetClients();
  const activateCases = useActivateCases();
  const deactivateCases = useDeactivateCases();
  const { data: currentUser } = useCurrentUser();
  const associationUsers = useAssociationUsers();
  const removeAssociationUsers = useRemoveAssociationUsers();
  const { customFields } = useGetCustomFields("Case");
  const navigate = useNavigate();
  const {
    cases,
    isLoading,
    isError,
    isRefetching,
    pagination,
    nextPage,
    prevPage,
    onChangePageRows,
    goToFirstPage,
  } = useGetCases({ filters });

  const [startDate, setStartDate] = useState<Date | null>(
    filters?.last_movement_date?.gte
      ? parseISO(filters?.last_movement_date?.gte as string)
      : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    filters?.last_movement_date?.lte
      ? parseISO(filters?.last_movement_date?.lte as string)
      : null,
  );

  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkPageAll, setCheckPageAll] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handlePageCheckAll = () => {
    setCheckAll(false);
    setCheckPageAll(!checkPageAll);
    const ids = [];
    for (const kase of cases) {
      ids.push(kase.id);
    }
    setSelectedIds(ids);
  };

  const handleItemCheck = (id: string) => {
    setCheckAll(false);
    setCheckPageAll(false);
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleClickActivate = (activate: boolean) => {
    const isWithIds = !checkAll;
    if (activate) {
      if (isWithIds) {
        activateCases.mutateAsync({
          filter: { id: { or: selectedIds } },
        });
      } else {
        activateCases.mutateAsync({
          filter: { ...filters },
        });
      }
    } else {
      if (isWithIds) {
        deactivateCases.mutateAsync({
          filter: { id: { or: selectedIds } },
        });
      } else {
        deactivateCases.mutateAsync({
          filter: { ...filters },
        });
      }
    }
  };

  const handleCheckAll = () => {
    setCheckPageAll(false);
    setCheckAll(!checkAll);
    const ids = [];
    for (const kase of cases) {
      ids.push(kase.id);
    }
    setSelectedIds(ids);
  };

  const handleUncheckAll = () => {
    setCheckPageAll(false);
    setCheckAll(false);
    setSelectedIds([]);
  };

  const onChangeDate = (date: Date | null) => {
    if (selectType === "start") {
      setSelectType("end");
      setStartDate(date ? startOfDay(date) : null);
      if (typeof date === "undefined" || date === null) {
        return handleFilter({
          ...filters,
          last_movement_date: {
            ...filters.last_movement_date,
            gte: undefined,
          },
          custom_data: {
            ...filters.custom_data,
          },
        });
      }
      return handleFilter({
        ...filters,
        last_movement_date: {
          ...filters.last_movement_date,
          gte: startOfDay(date).toISOString(),
        },
        custom_data: {
          ...filters.custom_data,
        },
      });
    }

    if (selectType === "end") {
      setEndDate(date ? endOfDay(date) : null);
      if (typeof date === "undefined" || date === null) {
        return handleFilter({
          ...filters,
          last_movement_date: {
            ...filters.last_movement_date,
            lte: undefined,
          },
          custom_data: {
            ...filters.custom_data,
          },
        });
      }
      return handleFilter({
        ...filters,
        last_movement_date: {
          ...filters.last_movement_date,
          lte: endOfDay(date)!.toISOString(),
        },
        custom_data: {
          ...filters.custom_data,
        },
      });
    }
  };

  const [selectType, setSelectType] = useState("start");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClickStart = (e: any) => {
    e.stopPropagation();
    setSelectType("start");
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClickEnd = (e: any) => {
    e.stopPropagation();
    setSelectType("end");
  };

  const onCalendarOpen = () => {
    setSelectType("start");
  };

  const {
    users,
    isLoading: isGetUsersLoading,
    isError: isGetUsersError,
  } = useGetUsers();

  const usersOptions = users.map((user) => ({
    label: user.name,
    value: user.id,
  }));

  const courtsOptions = useMemo(() => {
    return courts?.data?.map((court) => ({
      label: court.name,
      value: court.id,
    }));
  }, [courts]);

  const clientsOptions = useMemo(() => {
    return clients?.data?.map((client) => ({
      label: client.name,
      value: client.id,
    }));
  }, [clients]);

  const hasPermission = useUserAccess("cases-read");

  const debouncedSetFilters = useDebounce(setFilters, 500);

  const handleFilter = (newFilter: Partial<CaseFilter>) => {
    goToFirstPage();

    setFilters({
      ...filters,
      ...newFilter,
    });
  };

  const handleSearch = (search?: string) => {
    goToFirstPage();

    debouncedSetFilters({
      ...filters,
      ...(search && {
        q: search,
      }),
      ...(!search && {
        q: undefined,
      }),
    });
  };

  const handleAssingNewUsers = async (incomingUsers: string[]) => {
    const isWithIds = !checkAll;
    if (isWithIds) {
      associationUsers.mutateAsync({
        filter: { id: { or: selectedIds } },
        users: incomingUsers,
      });
    } else {
      associationUsers.mutateAsync({
        filter: { ...filters },
        users: incomingUsers,
      });
    }
  };

  const handleRemoveUsers = async (incomingUsers: string[]) => {
    const isWithIds = !checkAll;
    if (isWithIds) {
      removeAssociationUsers.mutateAsync({
        filter: { id: { or: selectedIds } },
        users: incomingUsers,
      });
    } else {
      removeAssociationUsers.mutateAsync({
        filter: { ...filters },
        users: incomingUsers,
      });
    }
  };

  const goToCaseDetail = (id: string) => () => {
    const selection = window.getSelection();

    if (selection?.type != "Range") {
      trackEvent("Case clicked");

      navigate({
        pathname: `${routes.cases}/${id}`,
        search: "?tab=movements",
      });
    }
  };

  if (!hasPermission) return null;

  if (isError || courts.isError)
    return (
      <PageContainer className="flex flex-col">
        <Box className="flex justify-between mb-4.5">
          <BreadcrumbNav />
        </Box>

        <PageTitle>{t("cases.cases")}</PageTitle>

        <Box className="flex justify-center items-center">
          <PageContainer.Error />
        </Box>
      </PageContainer>
    );

  const isEmpty = !isLoading && !cases?.length;
  const customFieldsInTable = [...customFields]
    .filter((field) => field.display_order_table > 0)
    .sort((a, b) => a.display_order_table - b.display_order_table);

  const customFieldsInFilter = [...customFields]
    .filter((field) => field.display_order_filter > 0)
    .sort((a, b) => a.display_order_filter - b.display_order_filter);

  return (
    <PageContainer className="flex flex-col">
      <Box className="flex justify-between mb-4.5">
        <BreadcrumbNav />
      </Box>

      <PageTitle>{t("cases.cases")}</PageTitle>

      <Box className="flex mt-4 mb-2">
        <Box className="flex items-center grow shrink max-w-[855px] mr-6">
          <Input.Group size="lg" className="grow shrink">
            <Input
              placeholder={t("cases.search_cases_placeholder")}
              className="w-full rounded-full peer"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Input.LeftElement className="opacity-50 peer-focus-visible:opacity-100 flex justify-center items-center w-10 h-10">
              <Icon as={MdSearch} size="6" className="text-neutral-600" />
            </Input.LeftElement>
          </Input.Group>

          <Checkbox
            className="ml-6"
            checked={!!filters?.active?.eq}
            onChange={() => {
              handleFilter({
                active: !filters?.active?.eq ? { eq: true } : undefined,
              });
            }}
          >
            {t("cases.actives_only")}
          </Checkbox>
        </Box>

        <UserAccess permission="cases-create">
          <Box className="ml-auto">
            <NewCaseModal />
          </Box>
        </UserAccess>
      </Box>

      <Box className="flex my-4 gap-2 flex-wrap" as="form">
        <DatePicker
          locale={locale}
          customInput={
            <FilterButton
              isSelected={!!startDate || !!endDate}
              className="whitespace-nowrap"
              onClear={() => {
                setStartDate(null);
                setEndDate(null);
                onChangeDate(null);

                return handleFilter({
                  ...filters,
                  last_movement_date: undefined,
                  custom_data: {
                    ...filters.custom_data,
                  },
                });
              }}
            >
              {t("cases.filter_last_movement")}{" "}
              {[startDate, endDate].filter(Boolean).length
                ? `(${[startDate, endDate].filter(Boolean).length})`
                : ""}
            </FilterButton>
          }
          selected={selectType === "start" ? startDate : endDate}
          onChange={onChangeDate}
          isHeaderClickeable={false}
          selectsStart={selectType === "start"}
          selectsEnd={selectType === "end"}
          startDate={startDate}
          endDate={endDate}
          minDate={selectType === "end" ? startDate : undefined}
          shouldCloseOnSelect={false}
          onCalendarOpen={onCalendarOpen}
          openToDate={
            startDate && endDate && selectType === "start"
              ? startDate
              : endDate!
          }
          monthsShown={2}
          topContent={
            <Box className="flex m-5 mb-1 items-center space-x-5">
              <Input
                size="sm"
                value={
                  startDate ? format(startDate, t("date_formats.date")) : ""
                }
                onClick={onClickStart}
                className={`${
                  selectType === "start" && "border-[1.5px] border-primary-800"
                }`}
              />

              <Icon as={MdOutlineArrowForward} />
              <Input
                size="sm"
                onClick={onClickEnd}
                value={endDate ? format(endDate, t("date_formats.date")) : ""}
                className={`${
                  selectType === "end" && "border-[1.5px] border-primary-800"
                }`}
              />
            </Box>
          }
        />

        <Dropdown>
          <Dropdown.Button
            as={FilterButton}
            className="whitespace-nowrap"
            isSelected={!!filters.court_id?.or?.length}
            onClear={() => {
              handleFilter({
                court_id: {
                  or: [],
                },
              });
            }}
          >
            {t("cases.filter_court")}{" "}
            {!!filters.court_id?.or?.length &&
              `(${filters.court_id?.or?.length})`}
          </Dropdown.Button>
          <Dropdown.List className="py-0 w-[380px]">
            <SelectMultiCheckbox
              options={courtsOptions}
              valueAsObject={false}
              isMulti
              removeShadows
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
              isClearable={false}
              disabled={courts.isLoading || courts.isError}
              placeholder={t("ui.search_placeholder")}
              value={filters.court_id?.or}
              onChange={(values: []) => {
                handleFilter({
                  court_id: {
                    or: [...values],
                  },
                });
              }}
            />
          </Dropdown.List>
        </Dropdown>

        {!clients.isLoading && currentUser?.role?.type !== "guest" && (
          <Dropdown>
            <Dropdown.Button
              as={FilterButton}
              className="whitespace-nowrap"
              isSelected={!!filters.client_id?.or?.length}
              onClear={() => {
                handleFilter({
                  client_id: {
                    or: [],
                  },
                });
              }}
            >
              {t("cases.filter_client")}{" "}
              {!!filters.client_id?.or?.length &&
                `(${filters.client_id?.or?.length})`}
            </Dropdown.Button>
            <Dropdown.List className="py-0 w-[380px]">
              <SelectMultiCheckbox
                options={clientsOptions}
                valueAsObject={false}
                isMulti
                removeShadows
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                menuIsOpen
                isClearable={false}
                disabled={clients.isLoading || clients.isError}
                placeholder={t("ui.search_placeholder")}
                value={filters.client_id?.or}
                onChange={(values: []) => {
                  handleFilter({
                    client_id: {
                      or: [...values],
                    },
                  });
                }}
              />
            </Dropdown.List>
          </Dropdown>
        )}

        <Dropdown>
          <Dropdown.Button
            as={FilterButton}
            className="whitespace-nowrap"
            isSelected={!!filters.user_ids?.or?.length}
            onClear={() => {
              handleFilter({
                user_ids: {
                  or: [],
                },
              });
            }}
          >
            {t("cases.filter_assigned_users")}{" "}
            {!!filters.user_ids?.or?.length &&
              `(${filters.user_ids?.or?.length})`}
          </Dropdown.Button>
          <Dropdown.List className="py-0 w-[380px]">
            <SelectMultiCheckbox
              options={usersOptions}
              valueAsObject={false}
              isMulti
              removeShadows
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              menuIsOpen
              isClearable={false}
              disabled={isGetUsersLoading || isGetUsersError}
              placeholder={t("ui.search_placeholder")}
              value={filters.user_ids?.or}
              onChange={(values: []) => {
                handleFilter({
                  ...filters,
                  user_ids: {
                    or: [...values],
                  },
                });
              }}
            />
          </Dropdown.List>
        </Dropdown>
        {customFieldsInFilter &&
          customFieldsInFilter.map((customField) => {
            if (customField.display_order_filter > 0) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const customData = (filters as any)["custom_data"];
              return (
                <CustomFieldFilter
                  id={customField.id}
                  name={customField.name}
                  defaultValue=""
                  value={
                    customData[customField.name]?.eq ||
                    customData[customField.name]?.lte ||
                    customData[customField.name]?.gte ||
                    customData[customField.name]?.or
                  }
                  model="Case"
                  onChange={(v, operator = "eq") => {
                    if (typeof v === "undefined" || v === null) {
                      return handleFilter({
                        ...filters,
                        custom_data: {
                          ...filters.custom_data,
                          [customField.name]: undefined,
                        },
                      });
                    }
                    return handleFilter({
                      ...filters,
                      custom_data: {
                        ...filters.custom_data,
                        [customField.name]: {
                          ...customData[customField.name],
                          [operator]: v,
                        },
                      },
                    });
                  }}
                />
              );
            }
          })}
      </Box>

      <Box className="flex mt-2 flex-col gap-2">
        <Box className="flex justify-between items-center">
          <Text className="text-primary-900 mb-2.5 font-semibold">
            {t("ui.showing_of_results", {
              showing: cases?.length,
              total: pagination?.total,
            })}
          </Text>

          <DownloadCases
            filters={filters}
            disabled={isEmpty || isLoading || isRefetching}
            className="mr-2"
          />

          <Box as="span" className="cursor-not-allowed">
            <Dropdown>
              <Dropdown.Button
                as={FilterButton}
                className={`whitespace-nowrap ${
                  selectedIds.length > 0 || checkAll || checkPageAll
                    ? ""
                    : "bg-[#EBEBE4]"
                }`}
                isSelected={false}
              >
                {t("cases.massive_actions_text")}
              </Dropdown.Button>
              <Dropdown.List className="py-0 ">
                {(selectedIds.length > 0 || checkAll || checkPageAll) && (
                  <>
                    <Confirm
                      title={t("cases.activation_causes_text")}
                      onConfirm={() => {
                        handleClickActivate(true);
                      }}
                      onConfirmText={t("cases.yes_activate_text")}
                      button={
                        <Dropdown.Item>
                          {t("cases.activate_case_text")}
                        </Dropdown.Item>
                      }
                      body={t("cases.activation_causes_warning_text", {
                        total: checkAll
                          ? pagination?.total
                          : selectedIds.length,
                      })}
                    />

                    <Confirm
                      title={t("cases.deactivation_causes_text")}
                      onConfirm={() => {
                        handleClickActivate(false);
                      }}
                      onConfirmText={t("cases.yes_deactivate_text")}
                      button={
                        <Dropdown.Item>
                          {t("cases.deactivate_case_text")}
                        </Dropdown.Item>
                      }
                      body={t("cases.deactivation_causes_warning_text", {
                        total: checkAll
                          ? pagination?.total
                          : selectedIds.length,
                      })}
                    />

                    {/* Assign users to cases */}
                    <CaseUserAssignModal
                      onClose={() => {}}
                      title={t("cases.association_users_text")}
                      submitButtonText={t("ui.continue")}
                      button={
                        <Dropdown.Item>
                          {t("cases.association_users_text")}
                        </Dropdown.Item>
                      }
                      onSubmit={handleAssingNewUsers}
                      total={checkAll ? pagination?.total : selectedIds.length}
                    />

                    {/* Remove users to cases */}
                    <CaseUserAssignModal
                      onClose={() => {}}
                      title={t("cases.unassociation_users_text")}
                      submitButtonText={t("ui.continue")}
                      button={
                        <Dropdown.Item>
                          {t("cases.unassociation_users_text")}
                        </Dropdown.Item>
                      }
                      onSubmit={handleRemoveUsers}
                      isAssign={false}
                    />
                  </>
                )}
              </Dropdown.List>
            </Dropdown>
          </Box>
        </Box>

        <Table.Container
          variant="elevated"
          className="rounded-md"
          isLoading={isLoading || courts.isLoading || isRefetching}
          page={pagination?.page}
          rowsPerPage={pagination?.pageSize}
          totalPages={pagination?.pageCount}
          onNextPage={nextPage}
          onPreviousPage={prevPage}
          onChangeRowsPerPage={onChangePageRows}
          totalRows={pagination?.total}
        >
          <Table>
            <Table.Head className="bg-primary-800 text-neutral-50">
              <Table.Row className="text-neutral-50">
                <Table.Header>
                  <Box as="span" className="ml-[-15px]">
                    <Table.Cell>
                      <ConfirmCheck
                        title={t("cases.confirm_selected_text")}
                        onConfirm={() => {
                          handlePageCheckAll();
                        }}
                        {...(pagination &&
                          pagination.total > 20 && {
                            onConfirmAll: () => {
                              handleCheckAll();
                            },
                          })}
                        onUncheck={() => {
                          handleUncheckAll();
                        }}
                        onConfirmText={t("cases.check_result_count", {
                          total: cases?.length,
                        })}
                        onConfirmAllText={t("cases.check_result_count", {
                          total: pagination?.total,
                        })}
                        button={
                          <Checkbox
                            checked={checkAll || checkPageAll}
                            className="ml-1"
                          />
                        }
                        body={t("cases.check_result_count_warning_text", {
                          total: pagination?.total,
                          count: cases?.length,
                        })}
                      />

                      <Box as="span" className="ml-1">
                        {t("cases.code")}
                      </Box>
                    </Table.Cell>
                  </Box>
                </Table.Header>
                <Table.Header>{t("cases.court")}</Table.Header>
                <Table.Header>{t("cases.client")}</Table.Header>
                <Table.Header>{t("cases.title")}</Table.Header>
                <Table.Header>{t("cases.last_movement")}</Table.Header>
                <Table.Header>{t("cases.connection")}</Table.Header>

                {customFieldsInTable.map((field) => (
                  <Table.Header key={field.id}>{field.name}</Table.Header>
                ))}

                <Table.Header className="w-24">
                  {t("cases.options")}
                </Table.Header>
              </Table.Row>
            </Table.Head>
            <TableBody
              isEmpty={isEmpty}
              title={t("ui.no_data")}
              className="bg-neutral-50"
            >
              {cases.map((item) => (
                <Table.Row
                  key={item.id}
                  className="even:bg-neutral-100 odd:bg-neutral-50 cursor-pointer"
                  onClick={goToCaseDetail(item.id)}
                >
                  <Table.Cell className="whitespace-nowrap">
                    <Checkbox
                      className="ml-1"
                      onClick={(e) => e.stopPropagation()}
                      checked={
                        selectedIds.includes(item.id) ||
                        checkAll ||
                        checkPageAll
                      }
                      {...(checkAll && {
                        disabled: true,
                      })}
                      {...(!checkAll && {
                        disabled: false,
                      })}
                      onChange={() => handleItemCheck(item.id)}
                    ></Checkbox>
                    <Box as="span" className="ml-1">
                      {item.code}
                    </Box>
                  </Table.Cell>
                  <Table.Cell className="max-w-[270px]">
                    {courts.courtMap.get(item?.court_id as string)?.name}
                  </Table.Cell>
                  <Table.Cell className="max-w-[270px]">
                    {clients.clientMap.get(item?.client_id as string)?.name}
                  </Table.Cell>
                  <Table.Cell className="font-semibold max-w-[270px]">
                    {item.title}
                    {!item.active && (
                      <Tag color="danger" className="bg-danger-100 ml-2">
                        <Tag.Label className="text-danger-400">
                          {t("cases.inactive")}
                        </Tag.Label>
                      </Tag>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {item?.last_movement_date &&
                      format(item?.last_movement_date, t("date_formats.date"))}
                  </Table.Cell>
                  <Table.Cell className="justify-center">
                    {!!item?.scraper_params?.url && (
                      <Tooltip label={item?.scraper_params?.scraper}>
                        <Box className="flex mx-auto">
                          <Icon
                            as={MdOutlinePublic}
                            className="text-primary-800"
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Table.Cell>
                  {customFieldsInTable.map((field) => (
                    <Table.Cell key={`case_table_custom_field_${field.id}`}>
                      {getCustomFieldValueFormatted(
                        item.custom_data?.[field.name],
                        field.type,
                      ) || "-"}
                    </Table.Cell>
                  ))}
                  <Table.Cell>
                    <Box className="flex justify-center w-full">
                      <Dropdown placement="bottom-end" withPortal>
                        <Dropdown.Button
                          as={IconButton}
                          color="neutral"
                          icon={<MdMoreVert />}
                          variant="text"
                        />

                        <Dropdown.List>
                          <Dropdown.Item
                            onClick={() => navigate(`/cases/${item.id}`)}
                          >
                            {t("ui.details")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={(e: any) => {
                              e.stopPropagation();
                              navigate({
                                pathname: `${routes.cases}/${item.id}`,
                                search: "?tab=users",
                              });
                            }}
                          >
                            {t("cases.associate_users")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={(e: any) => {
                              e.stopPropagation();
                              navigate({
                                pathname: `${routes.cases}/${item.id}`,
                                search: "?tab=users",
                              });
                            }}
                          >
                            {t("cases.disassociate_users")}
                          </Dropdown.Item>
                          <UserAccess permission="cases-destroy">
                            <DeleteCaseDropdownItem kase={item} />
                          </UserAccess>
                        </Dropdown.List>
                      </Dropdown>
                    </Box>
                  </Table.Cell>
                </Table.Row>
              ))}
            </TableBody>
          </Table>
          <TableFooter isEmpty={isEmpty} />
        </Table.Container>
      </Box>
    </PageContainer>
  );
}
