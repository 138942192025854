import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation } from "@tanstack/react-query";

export const validateToken = async ({ token }: { token: string }) => {
  const res = await fetcher.get<{ token: string }>(`/validate_token`, {
    params: { token },
  });

  return res.data;
};

export const useValidateToken = () => {
  return useMutation({
    mutationFn: validateToken,
  });
};
