import { ThemeProvider } from "@suit-ui/react";
import { AuthProvider } from "./features/session/providers/AuthProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import { splitSetup } from "./utils/analytics/splitSdk";
import { getTenantName } from "./features/session/useCases/getTenantName";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
    },
  },
});

export interface ProvidersProps {
  children?: React.ReactNode;
}

export const Providers = ({ children }: ProvidersProps) => {
  const config = splitSetup(getTenantName());

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <SplitFactoryProvider config={config}>
          <AuthProvider>{children}</AuthProvider>
        </SplitFactoryProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
